import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        data: Array,
    };

    connect () {
        import('../scripts/_leaflet').then(({ createMap, default: Leaflet }) => {
            Leaflet.Marker.prototype.options.icon = Leaflet.divIcon({
                className: 'marker-icon',
                iconSize: [12, 12],
                iconAnchor: [15, 15],
                popupAnchor: [-9, -20],
            });

            this.map = createMap(this.element, 47, 8.226667, 8);

            this.cluster = Leaflet.markerClusterGroup({
                // animate: false,
                // chunkedLoading: true,
                showCoverageOnHover: false,
                maxClusterRadius: 40,
            });

            const markers = [];

            this.dataValue.forEach(location => {
                const marker = Leaflet.marker([location.lat, location.lng], { draggable: false });
                const link = location.url ? `<br><a href="${location.url}">Details</a>` : '';
                const popup = Leaflet.popup().setContent(`<div data-turbo="false">${location.description}${link}</div>`);

                marker.bindPopup(popup)
                markers.push(marker);
            });

            this.cluster.addLayers(markers);
            this.map.addLayer(this.cluster);
        });
    }
}
