import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        lat: String,
        lng: String,
        share: String,
    };

    connect () {
        import('../scripts/_leaflet').then((module) => {
            this.map = module.createMap(this.element, this.latValue, this.lngValue);
            this.marker = module.default.marker([this.latValue, this.lngValue], { draggable: false }).addTo(this.map);

            if (this.shareValue) {
                new module.default.Control.Share({
                    position: 'bottomleft',
                    query: this.shareValue
                }).addTo(this.map);
            }
        })/*.catch(() => { window.location.reload(true) })*/;
    }
}
