import './styles/app.scss'
import './scripts/notfall.js'

import * as Turbo from "@hotwired/turbo"
Turbo.session.drive = false;

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

window.Stimulus = Application.start();
window.Stimulus.debug = process.env.NODE_ENV === 'development';
const context = require.context('./controllers', true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));
